<template>
  <div class="browseLesson">
    <!-- 头部 -->
    <headers ref="headers" :headname="headName"></headers>
    <!-- 输入框领取 -->
    <div class="getIpt">
      <div class="navIpt">
        <input id="" v-model="iptCode" placeholder="请输入兑换码" type="text" name="">
        <button @click="changeMaterialObject">兑换领取</button>
      </div>
    </div>
    <!-- 分割线 -->
    <div class="SplitLine"></div>
    <!-- 列表 -->
    <div v-if="islistlength" class="listBox">
      <div v-for="(item,index) in list" :key="index" class="listItem">
        <div class="listItemTop">
          <img :src="item.thumb" alt="">
          <p v-if="item.product_name.length>29" class="ptitlename">{{ `${item.product_name.substr(0,29)}...` }}</p>
          <p v-else class="ptitlename">{{ item.product_name }}</p>
        </div>
        <div class="listItemLine"></div>
        <div class="listItemBottom">
          <p>使用有效期至：{{ item.valid_end }}</p>
          <p id="copy_text" :data-clipboard-text="dhCode" @click="copy(item.redeem_code)">复制兑换码</p>
        </div>
      </div>
    </div>
    <div v-else class="qxtimg">
      <img style="margin:150px 0 0 0;" src="../../../assets/image/qxt.png" alt="">
      <p style="padding:20px 0 0 0;">您还没有未使用的兑换码~</p>
    </div>
    <!-- 兑换成功框 -->
    <el-dialog
      :visible.sync="successboxdialog"
      width="500px"
      center
    >
      <img v-if="errNo==0" class="imgcodeimg" src="@/assets/image/changeSuccess.png" alt="">
      <img v-if="errNo!=0" class="imgcodeimg" src="@/assets/image/changeError.png" alt="">
      <!-- <p class="namecode">{{ pct_name }}</p> -->
      <p class="namecode">{{ changeText }}</p>
      <div class="qdbtn">
        <p @click="wcoff()">关闭</p>
      </div>
    </el-dialog>
    <!-- 弹出框  请添加地址 -->
    <el-dialog
      title="选择地址"
      :visible.sync="centerDialogVisible"
      width="25%"
      center
    >
      <p class="addaddress" @click="openlist()">+ 请添加地址</p>
      <div class="dialogbtn">
        <p @click="centerDialogVisible = false">取消</p>
        <p>确定</p>
      </div>
    </el-dialog>
    <!-- 筑龙地址列表/新增地址 -->
    <el-dialog
      title="选择地址"
      :visible.sync="listAddDialogVisible"
      width="30%"
      center
    >
      <div v-show="isdzlb" class="addAddressbtn">
        <button @click="add()">新增收货地址</button>
      </div>
      <!-- 地址列表盒子 -->
      <div v-show="isdzlb" class="addressbox">
        <div v-for="(item,index) in addresslists" :key="index" class="addressList" @click="changeRed(item,index)">
          <div class="addressListtop">
            <div class="jsimgdiv">
              <img v-show="active == index" class="jbimg" src="../../../assets/image/jb.png" alt="">
              <p :class="{redname:active == index}" @click="changeRed(item,index)">{{ item.receiver }}</p>
            </div>

            <p style="font-size:18px;">{{ item.mobile }}</p>
            <p v-show="item.is_default">默认</p>
          </div>
          <div class="addressListcenter">
            <p>{{ `${item.province} ${item.city} ${item.county} ${item.detail_address}` }}</p>
          </div>
          <div class="addressListbottom">
            <div>

            </div>
            <div>
              <span v-show="item.is_default == 0" @click="defaults(item.id)">设为默认</span>
              <span @click.stop="getEdiLocalInfo(item.id)">编辑</span>
              <span @click.stop="del(item.id)">删除</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 新增地址盒子 -->
      <div v-show="isdzlb == false" class="addbox">
        <div class="pop_add_local">
          <ul>
            <li>
              <div>
                <span> *收货人：</span>
                <label>
                  <input
                    v-model="paramAdd_local_msg.receiver"
                    style="margin-left: 12px"
                    type="text"
                    placeholder="请填写收货人"
                  >
                </label>
              </div>
              <div>
                <span>*联系方式：</span>
                <label>
                  <input
                    v-model="paramAdd_local_msg.mobile"
                    type="text"
                    placeholder="请填写联系方式"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    maxlength="11"
                  >
                </label>
              </div>
              <div id="div_hang">
                <span>*所在地区：</span>
                <label>
                  <input
                    v-model="provinceAndCityName"
                    type="text"
                    placeholder="请填写所在地区"
                    readonly="readonly"
                    @click="isShowCityDialog(1)"
                  >
                </label>
              </div>
              <!--地选择城市弹窗-->
              <div v-show="isShowCityPop" id="pop_province" class="chooseCity">
                <p>
                  <span>所在地区</span>&nbsp;
                  <a v-if="this.cityLevel>1" href="javascript:void (0)" @click="backProvince()">[返回上一级]</a>
                  <img src="../../../assets/image/white_close.png" alt="close" @click="isShowCityDialog(0)">
                </p>
                <div class="province" style="margin: 0">
                  <ul>
                    <li
                      v-for="(item,index) in provinceData"
                      v-if="cityLevel===1"
                      :key="index"
                    >
                      <a class="pop_local_name" @click="showCity(item.id,item.cname)">{{ item.cname }}</a>
                    </li>

                    <li
                      v-for="item1 in cityData"
                      v-if="cityLevel===2"
                    >
                      <a
                        class="pop_local_name"
                        @click="showCity(item1.id,item1.cname)"
                      >{{ item1.cname }}</a>
                    </li>

                    <li
                      v-for="item2 in areaData"
                      v-if="cityLevel===3"
                    >
                      <a
                        class="pop_local_name"
                        @click="showCity(item2.id,item2.cname)"
                      >{{ item2.cname }}</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div>
                <span>*详细地址：</span>
                <label>
                  <input
                    v-model="paramAdd_local_msg.detail_address"
                    type="text"
                    placeholder="请填写详细地址"
                  >
                </label>
              </div>
            </li>
          </ul>
        </div>
        <button class="saves" @click="submitLocation()">保存</button>

      </div>
      <div v-show="isdzlb" class="dialogbtn">
        <p @click="listAddDialogVisible = false">取消</p>
        <p @click="go_exchanges()">完成</p>
      </div>
    </el-dialog>
    <!-- 京东地址列表/新增地址 -->
    <el-dialog
      title="选择地址"
      :visible.sync="JDlistAddDialogVisible"
      width="30%"
      center
    >
      <div v-show="isdzlb" class="addAddressbtn">
        <button @click="add()">新增收货地址</button>
      </div>
      <!-- 地址列表盒子 -->
      <div v-show="isdzlb" class="addressbox">
        <div v-for="(item,index) in JDlist" :key="index" class="addressList" @click="changeRed(item,index)">
          <div class="addressListtop">
            <div class="jsimgdiv">
              <img v-show="active == index" class="jbimg" src="../../../assets/image/jb.png" alt="">
              <p :class="{redname:active == index}" @click="changeRed(item,index)">{{ item.receiver }}</p>
            </div>

            <p>{{ item.mobile }}</p>
            <p v-show="item.is_default">默认</p>
          </div>
          <div class="addressListcenter">
            <p>{{ `${item.address_json.province} ${item.address_json.city} ${item.address_json.county} ${item.detail_address}` }}</p>
          </div>
          <div class="addressListbottom">
            <div>

            </div>
            <div>
              <span v-show="item.is_default == 0" @click.stop="JDdefaults(item.id)">设为默认</span>
              <span @click.stop="getEdiLocalInfo(item.id)">编辑</span>
              <span @click.stop="del(item.id)">删除</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 新增地址盒子 -->
      <div v-show="isdzlb == false" class="addbox">
        <div class="pop_add_local">
          <ul>
            <li>
              <div>
                <span> *收货人：</span>
                <label>
                  <input
                    v-model="paramAdd_local_msg.receiver"
                    style="margin-left: 12px"
                    type="text"
                    placeholder="请填写收货人"
                  >
                </label>
              </div>
              <div>
                <span>*联系方式：</span>
                <label>
                  <input
                    v-model="paramAdd_local_msg.mobile"
                    type="text"
                    placeholder="请填写联系方式"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    maxlength="11"
                  >
                </label>
              </div>
              <div id="div_hang">
                <span>*所在地区：</span>
                <label>
                  <input
                    v-model="provinceAndCityNamejd"
                    type="text"
                    placeholder="请填写所在地区"
                    readonly="readonly"
                    @click="isShowCityDialog(1)"
                  >
                </label>
              </div>
              <!-- 京东城市列表弹窗 -->
              <div v-show="JDisShowCityPop" id="pop_province" class="chooseCity">
                <p>
                  <span>所在地区</span>&nbsp;
                  <a v-if="cityLevel>1" href="javascript:void (0)" @click="backProvince()">[返回上一级]</a>
                  <img src="../../../assets/image/white_close.png" alt="close" @click="isShowCityDialog(0)">
                </p>
                <div class="province" style="margin: 0">
                  <ul>
                    <li
                      v-for="(item,index) in JDprovinceData"
                      v-show="cityLevel===1"
                      :key="index"
                    >
                      <a class="pop_local_name" @click="JDshowCity(item,index)">{{ index }}</a>
                    </li>

                    <li
                      v-for="(item1,index1) in JDcityData"
                      v-show="cityLevel===2"
                      :key="index1"
                    >
                      <a
                        class="pop_local_name"
                        @click="getqu(item1,index1)"
                      >{{ index1 }}</a>
                    </li>

                    <li
                      v-for="(item2,index2) in JDareaData"
                      v-show="cityLevel===3"
                      :key="index2"
                    >
                      <a
                        class="pop_local_name"
                        @click="JDshowtown(item2,index2)"
                      >{{ index2 }}</a>
                    </li>
                    <li
                      v-for="(item3,index3) in JDtown"
                      v-show="cityLevel===4"
                      :key="index3"
                    >
                      <a
                        class="pop_local_name"
                        @click="JDjd(item3,index3)"
                      >{{ index3 }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <span>*详细地址：</span>
                <label>
                  <input
                    v-model="paramAdd_local_msg.detail_address"
                    type="text"
                    placeholder="请填写详细地址"
                  >
                </label>
              </div>
            </li>
          </ul>
          <button class="saves" @click="jdSave()">保存</button>
        </div>

      </div>
      <div v-show="isdzlb" class="dialogbtn">
        <p @click="listAddDialogVisible = false">取消</p>
        <p @click="go_exchanges()">完成</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import headers from '@/components/topTitle/index.vue'
import { addjdAddress, getJdTown, getJdQu, getJdShi, getlist, productinfo, addressList, set_default_address, redeem, getAddressList, set_jdDefault_address, getJdProvince } from '@/api/exchangeCode.js'
import Clipboard from 'clipboard'
import { getUserAddressListThree, editAddress, getNeedEdiLocalInfo } from '../../../api/mine'
export default {
  components: { headers },
  data() {
    return {
      headName: '未使用',
      types: 1,
      list: [],
      addresslists: [],
      JDprovinceData: [],
      JDcityData: [],
      JDareaData: [],
      iptCode: '',
      dhCode: '',
      pct_name: '',
      thumb: '',
      product_type: '',
      must_send: '',
      provinceAndCityName: '',
      JDtown: [],
      islistlength: true,
      active: null,
      isdzlb: true,
      centerDialogVisible: false,
      listAddDialogVisible: false,
      successboxdialog: false,
      errNo: '',
      isShowAddPop: false,
      isShowCityPop: false,
      JDisShowCityPop: false,
      isShowDelPop: false,
      isShowYinYing: false,
      show_address_list: false,
      canClick: true,
      addresslength: false,
      JDaddresslength: false,
      JDlistAddDialogVisible: false,
      addressId: '',
      provinceAndCityNamejd: '',
      cityLevel: 0,
      JDlist: [],
      addressParams: {
        receiver: '',
        mobile: '',
        province_id: '',
        province: '',
        city_id: '',
        city: '',
        town_id: '',
        town: '',
        id: '',
        // county_id: this.quId ? this.quId : 0,
        county_id: 0,
        // county: this.quName ? this.quName : '',
        county: '',
        detail_address: ''
      },
      params_citys: {
        parent_id: ''
      },
      // 获取编辑信息入参
      paramGetEdiInfo: {
        id: ''
      },
      needEdiMsg: {
        id: '', // 类型：Number  必有字段  备注：无
        province_id: '',
        city_id: '',
        county_id: '',
        receiver: '', // 类型：String  必有字段  备注：收货人
        mobile: '', // 类型：String  必有字段  备注：手机号
        is_default: '', // 类型：Number  必有字段  备注：是否是默认地址 1是 0 否
        province: '', // 类型：String  必有字段  备注：无
        city: '', // 类型：String  必有字段  备注：无
        detail_address: '',
        county: ''

      },
      paramAdd_local_msg: {
        id: '',
        receiver: '',
        mobile: '',
        province_id: '',
        city_id: '',
        county_id: '',
        detail_address: ''
      },
      provinceData: [{
        id: '',
        parent_id: '',
        cname: '',
        level: ''
      }],
      cityData: [{
        id: '',
        parent_id: '',
        cname: '',
        level: ''
      }],
      areaData: [{
        id: '',
        parent_id: '',
        cname: '',
        level: ''
      }],
      changeText: ''
    }
  },

  computed: {},

  created() {
    this.getList()
    this.addresslist()
    this.getProvinceCity()
    this.getsheng()
    this.JDaddressList()
  },

  mounted() {

  },

  methods: {
    jdSave() {
      var params = {
        receiver: this.paramAdd_local_msg.receiver,
        mobile: this.paramAdd_local_msg.mobile,
        province_id: this.addressParams.province_id,
        province: this.addressParams.province,
        city_id: this.addressParams.city_id,
        city: this.addressParams.city,
        town_id: this.addressParams.town_id,
        town: this.addressParams.town,
        id: '',
        county_id: this.addressParams.county_id ? this.addressParams.county_id : 0,
        county: this.addressParams.county ? this.addressParams.county : '',
        detail_address: this.paramAdd_local_msg.detail_address
      }
      addjdAddress(params).then(res => {
        console.log(res)
        if (res.errNo !== 0) {
          this.$message({
            message: res.result,
            type: 'warning'
          })
        } else {
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          this.isdzlb = true
          this.JDaddressList()
        }
      })
      console.log(params)
    },
    // 街道赋值
    JDjd(id, cname) {
      this.addressParams.town_id = id
      this.addressParams.town = cname
      this.townName = cname
      this.provinceAndCityNamejd = this.provinceName + '  ' + this.cityName + ' ' + this.areaName + ' ' + this.townName
      this.JDisShowCityPop = false
    },
    // 根据区id拿街道
    JDshowtown(id, cname) {
      this.areaName = cname
      this.addressParams.county_id = id
      this.addressParams.county = cname
      this.provinceAndCityNamejd = this.provinceName + '  ' + this.cityName + ' ' + this.areaName
      var params = {
        id: id
      }
      getJdTown(params).then(res => {
        console.log(res)
        this.JDtown = res.result
        if (this.JDareaData != {}) {
          this.cityLevel = 4
        } else {
          this.JDisShowCityPop = false
        }
      })
    },
    // 获取市id对应的区列表
    getqu(id, cname) {
      this.cityName = cname
      this.addressParams.city_id = id
      this.addressParams.city = cname
      this.provinceAndCityNamejd = this.provinceName + '  ' + this.cityName
      // this.provinceAndCityName = this.provinceName + '  ' + this.cityName + ' ' + this.areaName
      var params = {
        id: id
      }
      getJdQu(params).then((res) => {
        this.JDareaData = res.result
        if (this.JDareaData != {}) {
          this.cityLevel = 3
        } else {
          this.JDisShowCityPop = false
        }
        console.log(res)
      })
    },
    // JD获取省
    getsheng() {
      getJdProvince().then((res) => {
        this.JDprovinceData = res.result
        console.log(res)
      })
    },
    // 京东地址列表
    JDaddressList() {
      this.active = null
      getAddressList().then(res => {
        this.JDlist = res.result.list
        this.JDlist.forEach((item, index) => {
          if (item.is_default == 1) {
            this.active = index
            this.addressId = item.id
            console.log(this.addressId, 'this.addressId')
          }
        })
        if (res.result.count < 1) {
          this.JDaddresslength = true
        }
      })
    },
    go_exchanges() {
      if (!this.addressId) {
        this.$message({
          message: '请选择地址',
          type: 'warning'
        })
        return false
      }
      var params = {
        code: this.iptCode,
        address_id: this.addressId
      }
      redeem(params).then(res => {
        this.errNo = res.errNo
        if (res.errNo == 0) {
          // this.$message({
          //   message: '兑换成功',
          //   type: 'success'
          // })
          this.changeText = '恭喜您兑换成功！'
          this.listAddDialogVisible = false
          this.iptCode = ''
          this.successboxdialog = true
          this.getList()
        } else {
          this.changeText = res.msg
          this.successboxdialog = true
          // this.$message({
          //   message: res.msg,
          //   type: 'warning'
          // })
        }
      })
    },
    wcoff() {
      this.successboxdialog = false
      this.centerDialogVisible = false
    },
    openlist() {
      if (this.product_type == 1 || this.must_send == 1) {
        this.addresslist()
        this.listAddDialogVisible = true
      } else {
        this.JDaddressList()
        this.JDlistAddDialogVisible = true
      }
    },
    /* 控制添加地址弹窗*/
    isShowAddDialog(isShow, needEdiMag) {
      if (this.allReadyHas < 5 || needEdiMag != null || isShow === 0) {
        if (needEdiMag != null) {
          console.log('*')
          // this.dialogTitle = '编辑'
          this.paramAdd_local_msg.receiver = needEdiMag.receiver
          this.paramAdd_local_msg.mobile = needEdiMag.mobile
          this.paramAdd_local_msg.province_id = needEdiMag.province_id
          this.paramAdd_local_msg.city_id = needEdiMag.city_id
          this.paramAdd_local_msg.county_id = needEdiMag.county_id
          this.paramAdd_local_msg.detail_address = needEdiMag.detail_address
          if (needEdiMag.province != null) {
            this.provinceAndCityName = needEdiMag.province
            if (needEdiMag.city != null) {
              this.provinceAndCityName = needEdiMag.province + ' ' + needEdiMag.city
              if (needEdiMag.county != null) {
                this.provinceAndCityName = needEdiMag.province + ' ' + needEdiMag.city + ' ' + needEdiMag.county
              }
            }
          }
        } else {
          this.dialogTitle = '新增'
          this.paramAdd_local_msg.id = ''
        }
        if (isShow === 1) {
          this.isdzlb = false
          // this.isShowYinYing = true
        } else {
          this.isShowCityPop = false
          this.isShowAddPop = false
          this.isShowYinYing = false
          /* 清空提交信息*/
          this.paramAdd_local_msg.id = ''
          this.paramAdd_local_msg.receiver = ''
          this.paramAdd_local_msg.mobile = ''
          this.paramAdd_local_msg.province_id = ''
          this.paramAdd_local_msg.city_id = ''
          this.paramAdd_local_msg.county_id = ''
          this.paramAdd_local_msg.detail_address = ''
          this.provinceAndCityName = ''
        }
      } else {
        this.warningTip('最多添加五个收货地址')
      }
    },
    /* 获取需要编辑的信息*/
    getEdiLocalInfo(id) {
      this.paramGetEdiInfo.id = id
      this.paramAdd_local_msg.id = id
      // 获取需要编辑的信息
      getNeedEdiLocalInfo(this.paramGetEdiInfo).then((res) => {
        if (parseInt(res.errNo) === 0) {
          this.needEdiMsg = res.result
          // 显示编辑弹窗
          this.isShowAddDialog(1, this.needEdiMsg)
        }
      })
    },
    warningTip(msg) {
      this.$message({
        message: msg,
        type: 'warning'
      })
    },
    changeRed(item, index) {
      this.addressId = item.id
      this.active = index
      console.log(this.addressId)
    },
    /* 添加/编辑信息*/
    submitLocation() {
      if (!this.paramAdd_local_msg.receiver) {
        this.warningTip('收货人不能为空')
        return
      }
      if (!this.paramAdd_local_msg.mobile) {
        this.warningTip('联系方式不能为空')
        return
      } else if (!(/^1\d{10}$/.test(this.paramAdd_local_msg.mobile))) {
        this.warningTip('请输入有效的手机号')
        return
      }
      if (!this.paramAdd_local_msg.province_id) {
        this.warningTip('所在地区不能为空')
        return
      } else if (!this.paramAdd_local_msg.city_id) {
        this.warningTip('所在地区市区不能为空')
        return
      }
      if (!this.paramAdd_local_msg.detail_address) {
        this.warningTip('详细地址不能为空')
        return
      }
      this.isShowYinYing = false
      this.isShowAddPop = false
      this.isShowCityPop = false
      editAddress(this.paramAdd_local_msg).then((res) => {
        if (parseInt(res.errNo) === 0) {
          this.isdzlb = true
          this.addresslist()
        }
      })
      /* 清空提交信息*/
      this.paramAdd_local_msg.id = ''
      this.paramAdd_local_msg.receiver = ''
      this.paramAdd_local_msg.mobile = ''
      this.paramAdd_local_msg.province_id = ''
      this.paramAdd_local_msg.city_id = ''
      this.paramAdd_local_msg.county_id = ''
      this.paramAdd_local_msg.detail_address = ''
      this.provinceAndCityName = ''
    },
    // 京东显示市列表
    JDshowCity(id, cname) {
      this.provinceName = cname
      this.addressParams.province_id = id
      this.addressParams.province = cname
      this.provinceAndCityNamejd = this.provinceName
      var params = {
        id: id
      }
      getJdShi(params).then((res) => {
        this.JDcityData = res.result
        if (this.JDcityData != {}) {
          this.cityLevel = 2
        }
        console.log(res)
      })
    },
    /* 显示城市列表*/
    showCity(pid, cname) {
      if (this.cityLevel > 2) {
        // 选择完毕
        this.paramAdd_local_msg.county_id = pid
        this.isShowCityPop = false
        this.isShowYinYing = false
        this.areaName = cname
        this.provinceAndCityName = this.provinceName + '  ' + this.cityName + ' ' + this.areaName
        this.cityLevel = 1
      } else {
        this.params_citys.parent_id = pid
        if (this.cityLevel === 1) {
          this.paramAdd_local_msg.province_id = pid
          this.paramAdd_local_msg.city_id = ''
          this.paramAdd_local_msg.county_id = ''
          this.provinceName = cname
          this.provinceAndCityName = this.provinceName
        } else {
          this.cityName = cname
          this.paramAdd_local_msg.city_id = pid
          this.paramAdd_local_msg.county_id = ''
          this.provinceAndCityName = this.provinceName + ' ' + this.cityName
          this.params_citys.parent_id = pid
        }

        this.getProvinceCity()
      }
    },
    /* 显示省市列表*/
    backProvince() {
      this.cityLevel--
    },

    getProvinceCity() {
      if (this.canClick) {
        this.canClick = false
        getUserAddressListThree(this.params_citys).then((res) => {
          this.canClick = true
          if (parseInt(res.errNo) === 0) {
            if (this.cityLevel === 0) {
              this.provinceData = res.result
            } else if (this.cityLevel === 1) {
              this.isShowCityPop = true
              this.isShowYinYing = true
              this.cityData = res.result
            } else {
              this.isShowCityPop = true
              this.isShowYinYing = true
              this.areaData = res.result
              if (this.areaData.length === 0) {
                this.isShowCityPop = false
                this.cityLevel = 0
              }
            }
            this.cityLevel++
          }
        })
      }
    },
    /* 控制城市弹窗*/
    isShowCityDialog(isShow) {
      console.log(this.product_type, 'this.product_type')
      if (this.product_type == 1 || this.must_send == 1) {
        if (isShow !== 1) {
          this.isShowCityPop = false
          this.isShowYinYing = false
          this.cityLevel = 1
        } else {
          this.isShowCityPop = true
          this.isShowYinYing = true
        }
      } else if (this.product_type == 2) {
        if (isShow !== 1) {
          this.JDisShowCityPop = false
          this.JDisShowCityPop = false
          this.cityLevel = 1
        } else {
          this.JDisShowCityPop = true
          this.JDisShowCityPop = true
        }
      }
    },
    // 新增收货地址
    add() {
      this.isdzlb = false
      this.JDisShowCityPop = false
    },
    // 删除地址
    del(ids) {
      const params = {
        id: ids,
        status: 0
      }
      set_default_address(params).then(res => {
        this.addresslist()
        this.$message({
          message: '删除成功',
          type: 'success'
        })
      })
    },
    // 获取筑龙地址列表
    addresslist() {
      addressList().then(res => {
        this.addresslists = res.result.list
        this.addresslists.forEach((item, index) => {
          if (item.is_default == 1) {
            this.active = index
            this.addressId = item.id
            console.log(this.addressId, 'this.addressId')
          }
        })
        if (res.result.count < 1) {
          this.addresslength = true
        }
      })
    },
    // 复制
    copy(code) {
      this.dhCode = code
      var clipboard = new Clipboard('#copy_text')

      clipboard.on('success', (e) => {
        console.log(e.text)
        this.$message({
          message: '已复制兑换码到剪切板',
          type: 'success'
        })
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        // 释放内存
        clipboard.destroy()
      })
    },
    // 兑换领取
    changeMaterialObject() {
      this.isdzlb = true
      var params = {
        code: this.iptCode
      }
      productinfo(params).then(res => {
        this.errNo = res.errNo
        if (res.errNo !== 0) {
          // this.$message({
          //   message: res.msg,
          //   type: 'warning'
          // })
          this.changeText = res.msg
          this.successboxdialog = true
        } else {
          this.pct_name = res.result.product_name
          this.thumb = res.result.thumb
          this.product_type = res.result.product_type
          this.must_send = res.result.must_send
          console.log( res.result.must_send,+"must_send")
          if ((this.product_type == 1 || res.result.must_send == 1)&& this.addresslength) {
            this.centerDialogVisible = true
            return false
          } else if ((this.product_type == 1|| res.result.must_send == 1) && this.addresslength == false) {
            this.addresslist()
            this.listAddDialogVisible = true
            return false
          }
          if (this.product_type == 2 && this.JDaddresslength) {
            this.centerDialogVisible = true
            return false
          } else if (this.product_type == 2 && this.JDaddresslength == false) {
            this.active = null
            this.JDaddressList()
            this.JDlistAddDialogVisible = true
            return false
          }
          if (this.product_type !== 1 && this.product_type !== 2) {
            var params = {
              code: this.iptCode
            }
            redeem(params).then(res => {
              this.errNo = res.errNo
              if (res.errNo == 0) {
                // this.$message({
                //   message: '兑换成功',
                //   type: 'success'
                // })
                this.changeText = '恭喜您兑换成功！'
                this.successboxdialog = true
                this.getList()
              } else {
                this.changeText = res.msg
                this.successboxdialog = true
                // this.$message({
                //   message: res.msg,
                //   type: 'warning'
                // })
              }
            })
          }
          console.log(this.product_type)
        }
      })
    },
    // 设为默认地址
    defaults(ids) {
      const params = {
        id: ids,
        is_default: 1
      }
      set_default_address(params).then(res => {
        this.addresslist()
      })
    },
    // 京东设为默认地址
    JDdefaults(ids) {
      const params = {
        id: ids,
        is_default: 1
      }
      set_jdDefault_address(params).then(res => {
        this.JDaddressList()
      })
    },
    // 列表
    getList() {
      var params = {
        type: this.types
      }
      getlist(params).then(res => {
        if (res.errNo == 0) {
          this.list = res.result.list
          if (res.result.count == 0) {
            this.islistlength = false
          }
        }
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.addbox{
  min-height: 400px;
}
.ptitlename{
    width: 270px;
    padding: 12px 0 0 7px;
    color: #999999;
    font-size: 17px;
    font-weight: 600;

  }
.qdbtn{
  display: flex;
  align-items: center;
  justify-content: center;
  // border-top: 1px solid #dedede;
  margin: 30px 0 0;
  padding-bottom: 30px;

  cursor: pointer;
  p{
    width: 70px;
    background: #ee2e2e;
    color: #fff;
    font-size: 16px;
    text-align: center;
    padding: 6px 0;
    border-radius: 4px;
  }
}
.namecode{
  display: block;
  padding-top: 15px;
  margin: 0 auto;
  text-align: center;
  font-size: 18px;
  // width: 480px;
}
.imgcodeimg{
  display: block;
  margin: 0 auto;
  width: 154px;
  // height: 110px;
  margin-top: 30px;
}
.jsimgdiv{
  position: relative;
}
.jbimg{
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0;
  bottom: 0;
}
.redname{
  border: 1px solid #ee2e2e !important;
  font-size: 18px;
}
.province {
        margin: 0;
    }

    .province ul {
        padding: 0 0 0 24px;
    }

    .province ul li {
        float: left;
        margin-top: 12px;
        width: 70px;
        text-align: center;
        height: 18px;
    }

    .province ul li a {
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        color: #444444;
    }
.province ul li a :hover {
        color: #ee2e2e;
    }
.saves{
  display: block;
  margin: 0 auto;
  width: 90px;
        height: 30px;
        border-radius: 4px;
        background-color: #ee2e2e;
        border: 1px solid #ee2e2e;
        margin-top: 33px;
        cursor: pointer;
        color: white;
        margin-bottom: 30px;
}
.pop_add_local img {
        position: absolute;
        top: 3%;
        left: 100%;
        width: 24px;
        height: 24px;
        margin-left: -32px;
    }

    .pop_add_local p {
        font-size: 18px;
        color: #444444;
    }

    .pop_add_local li div {
        margin-top: 22px;
    }

    .pop_add_local li input {
        padding-left: 25px;
        width: 302px;
        height: 46px;
        line-height: 46px;
        background: none;
        border: none;
        outline: 1px solid #ccc; //边框不用border，用outline
    }

    .pop_add_local span {
        font-size: 14px;
        color: #999999;
    }

    button {
        outline: none;
    }

    .pop_add_local button {
        width: 90px;
        height: 30px;
        border-radius: 4px;

        background-color: #ee2e2e;
        border: 1px solid #ee2e2e;
        margin-top: 33px;
        cursor: pointer;
        color: white;
    }
.pop_add_local {
        text-align: center;
        width: 516px;
        min-height: 303px;
        margin: 0 0 0 0;
        // box-shadow: 0 0 32px 0 rgba(193, 193, 193, 0.72);
        padding-top: 26px;
        background: #ffffff;
        z-index: 1;
    }
.div_hang {
        position: relative;
        background: gray;
        margin: 0 auto;
    }
.addressbox{
  height: 400px;
  padding: 0 0 73px 0;
  overflow-y: scroll;
}
.addressListbottom{
  display: flex;
  justify-content: space-between ;
  height:30px;
  span{
    color: #ee2e2e;
    padding: 0 0 0 10px;
    cursor: pointer;
  }
}
.addressListcenter{
  min-height: 63px;
}
/deep/ .el-dialog__title{
  font-size: 22px;
  // font-weight: 600;
}
.addressListcenter p{
  font-size: 16px;
  color: #999999;
  padding-top: 16px;
  padding-bottom:20px ;
}
.addressListtop{
  display: flex;
}
.addressListtop p:nth-child(3){
  width: 49px;
  font-size: 14px;
  height: 24px;
  color: #fff;
  text-align: center;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 4px 0 0 15px;
  background: #EE2E2E;
}
.addressListtop p:nth-child(2){
  height: 32px;
  color: #333;
  text-align: center;
  line-height: 32px;
  letter-spacing: 1px;
  padding: 0 0 0 15px;
  font-size: 18px;
}
.addressListtop div p{
  min-width: 120px;
  height: 32px;
  border: 1px solid #DEDEDE;
  color: #333;
  text-align: center;
  line-height: 32px;
  letter-spacing: 1px;
  cursor: pointer;
  padding: 0 0 0 0 !important;
}
.addressList{
  cursor: pointer;
  width: 90%;
  margin: 0 auto;
  margin-top: 25px;
  border-bottom: 1px solid #dedede;
}
/deep/ .el-dialog__body{
  padding: 0 0 0 0;
}
.addAddressbtn{
  width: 100%;
  display: block;
  height: 50px;
  button{
    width: 126px;
    height: 34px;
    border: 1px solid #EE2E2E;
    border-radius: 17px;
    color: #ee2e2e;
    background: #fff;
    float: right;
    margin: 0 40px 0 0;
    cursor: pointer;
  }
}
.dialogbtn p:nth-child(2){
  color: #ee2e2e;

}
.dialogbtn{
  background: #fff;
  width: 100%;
  height: 73px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #DEDEDE;
  p{
    line-height: 40px;
    width: 50%;
    text-align: center;
    border-right: 1px solid #DEDEDE;
    display: inline-block;
    height: 40px;
    margin-top: 15px;
    font-size: 20px;
    cursor: pointer;
  }
}
/deep/ .el-dialog{
  border-radius: 5px;
  position: relative;
  min-height: 250px;
}
.addaddress{
  text-align: center;
    color: #F40E0E;
  font-size: 20px;
  padding-top: 40px;
  cursor: pointer;
}
.listItemBottom p:nth-child(2){
    color: #F40E0E;
    padding: 0px 12px 0 0px;
    cursor: pointer;
}
.listItemBottom{
  font-size: 14px;
  color: #999;
  padding: 12px 0 0 12px;
  display: flex;
  justify-content: space-between;
}
.listItemLine{
  width: 406px;
  height: 0.5px;
  margin: 0 0 0 12px;
  background: #E9E9E9;
}
.listItemTop{
  width: 100%;
  height: 104px;
  display: flex;
  text-align: left;
  img{
    width: 115px;
    height: 82px;
    display: block;
    margin: 12px 0 0 12px;
  }
  p{
    width: 270px;
    padding: 12px 0 0 7px;
    color: #333;
    font-size: 17px;
    font-weight: 600;
  }
}
.listItem{
  border: 1px solid #EBEBEB;
  box-shadow: 0 2px 15px 8px #F8F8F8;
  width: 430px;
  height: 149px;
  margin: 0 0 30px 0;
  border-radius: 10px;
}
.listBox{
  width: 890px;
  margin: 30px 0 0 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
  .SplitLine{
      height: 1px;
      width: 927px;
      margin: 20px 0 0 39px;
      background: #DEDEDE;
  }
  .navIpt{
    margin: 30px 0 0 38px;
    input{
      width: 314px;
      height: 50px;
      border: none;
      border-radius: 25px 0px 0px 25px;
      background: #F3F3F3;
      color: #999;
      padding-left: 22px;
      outline: none;
      font-size: 17px;
    }
    button{
      width: 130px;
      height: 50px;
      background: #ee2e2e;
      color: #fff;
      border: none;
      border-radius: 0px 25px 25px 0px;
      cursor: pointer;
      font-size: 17px;
    }
  }
  .getIpt{
    width: 100%;
    height: 50px;
    text-align: left;
  }
  .chooseCity {
        position: absolute;
        top: 43%;
        left: 70%;
        margin-top: -150px;
        width: 390px;
        padding-bottom: 12px;
        background: #ffffff;
        padding-top: 17px;
        box-shadow: 0 0 32px 0 rgba(193, 193, 193, 0.72);
    }

    .chooseCity span {
        font-size: 15px;
        color: #444444;
    }

    .chooseCity a {
        color: #ee2e2e;
        font-size: 15px;
    }

    .chooseCity img {
        left: 100%;
        top: 3%;
        width: 24px;
        height: 24px;
        margin-left: -32px;
    }
</style>
